import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { Box, Container, Hidden, Typography, Button, Dialog } from '@material-ui/core';
import { HelpVideo } from '@product-site-frontend/shared';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

import arenzaSmile from '../assets/svg/arenza-smile.svg';
import BackgroundDesktop from "./BackgroundDesktop";

function Caption(props) {
  return (
    <Typography
      component="div"
      sx={{ color: "text.secondary", fontSize: 16, lineHeight: 1.4 }}
      variant="caption"
      {...props}
    />
  );
}

SectionMainPromos.propTypes = {
  ApplicationButton: PropTypes.node,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    bgDesktop: PropTypes.object,
    titleDealAmount: PropTypes.string,
    descriptionDealAmount: PropTypes.string,
    showDealAmount: PropTypes.bool,
    titleAdvanceRate: PropTypes.string,
    descriptionAdvanceRate: PropTypes.string,
    showAdvanceRate: PropTypes.bool,
    titleTerm: PropTypes.string,
    descriptionTerm: PropTypes.string,
    showTerm: PropTypes.bool,
    showQuiz: PropTypes.bool,
    quizButtonText: PropTypes.string,
    quizUrl: PropTypes.string
  }),
};

export default function SectionMainPromos({ ApplicationButton, content }) {
  const [openModal, setOpenModal] = useState(false);

  const onClickOpenModal = useCallback((e) => {
    e.preventDefault();

    setOpenModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Box id={"sectionMainPromos"} overflow="hidden" position="relative">
      <Hidden mdDown>
        <Box
          sx={{
            bottom: -340,
            left: 720,
            position: "absolute",
            right: 0,
            width: 720,
            display: { xs: "none", lg: "block" },
          }}
        >
          <BackgroundDesktop />
        </Box>

        {process.env.GATSBY_PARTNER_SLUG !== '8let' && (
          <Box
            alt="Arenza"
            bottom={16}
            component="img"
            position="absolute"
            right={16}
            src={arenzaSmile}
            width={160}
            zIndex={1}
          />
        )}
      </Hidden>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection={{ xs: "column-reverse", lg: "row" }} mt={{ lg: 8 }}>
          <Box maxWidth={640} mt={{ xs: 4, lg: 0 }} width={{ xs: 1, lg: 0.6 }}>
            <Typography sx={{ mb: { xs: 4, lg: 6 } }} variant="h1">
              {parse(content.title)}
            </Typography>
            <Typography sx={{ mb: 6 }} variant="subtitle1">
              {parse(content.description)}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: 'flex-start',
                maxWidth: 600,
                mb: 8,
                overflow: "auto",
                gap: "32px"
              }}
            >
              {content.showDealAmount && (
                <Box>
                  <Typography component="div" variant="h2">
                    {content?.titleDealAmount}
                  </Typography>

                  <Caption>{content.descriptionDealAmount}</Caption>
                </Box>
              )}

              {content.showAdvanceRate && (
                <Box>
                  <Typography component="div" variant="h2">
                    {content?.titleAdvanceRate}
                  </Typography>
                  <Caption>{content.descriptionAdvanceRate}</Caption>
                </Box>
              )}

              {content.showTerm && (
                <Box>
                  <Typography component="div" variant="h2">
                    {content?.titleTerm}
                  </Typography>
                  <Caption>{content.descriptionTerm}</Caption>
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              justifyContent={{ xs: "center", lg: "normal" }}
              sx={{
                gap: {
                  xs: 0,
                  md: '16px',
                  lg: '16px'
                }
              }}
            >
              <Box
                maxWidth={375}
                mb={{ xs: 3, lg: 0 }}
                py={2}
                width={{ xs: 1, lg: "auto" }}
              >
                {ApplicationButton}
              </Box>
              {content?.showQuiz ? (
                <Box
                  maxWidth={375}
                  mb={{ xs: 3, lg: 0 }}
                  py={2}
                  width={{ xs: 1, lg: "auto" }}
                >
                  <Button
                    color="primary"
                    fullWidth
                    onClick={onClickOpenModal}
                    size="large"
                    variant="contained"
                  >
                    {content?.quizButtonText ? content.quizButtonText : 'Ответить'}
                  </Button>
                </Box>
              ) : (
                <Box maxWidth={375} ml={{ lg: 3 }} width={{ xs: 1, lg: "auto" }}>
                  <HelpVideo
                    videoText={
                      <>
                        узнайте все о лизинге
                        <br />
                        за 80 секунд
                      </>
                    }
                    videoUrl="https://www.youtube.com/watch?v=VJ1wK48wOQs"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box flex={1} position="relative">
            <Box
              sx={{
                display: { lg: "none" },
                position: "absolute",
                left: 0,
                right: 0,
                mx: { xs: -3, sm: -4 },
                top: 0,
                bottom: 0,
                backgroundImage: "linear-gradient(119deg, #ff720a 23%, #ffaa12 90%)",
              }}
            />

            {process.env.GATSBY_PARTNER_SLUG !== '8let' && (
              <Box
                alt="Arenza"
                component="img"
                src={arenzaSmile}
                sx={{
                  display: { lg: "none" },
                  position: "absolute",
                  bottom: 16,
                  right: 16,
                  width: 72,
                }}
              />
            )}

            <Box
              alt="Лизинг оборудования для вашего бизнеса"
              component={GatsbyImage}
              image={content.bgDesktop.localFile.childImageSharp.gatsbyImageData}
              objectFit="contain"
              sx={{
                zIndex: 2,
                width: '90%',
                ml: { xs: 0, md: '10%' },
                mt: { xs: 4, lg: 0 },
                maxHeight: { xs: 200, md: 256, lg: 520 },

                '& img': {
                  objectFit: 'contain !important',
                },
              }}
            />
          </Box>
        </Box>
      </Container>

      {content?.quizUrl && (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          onClose={onCloseModal}
          open={openModal}
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '860px',
              borderRadius: {
                md: '24px',
                xs: '6px'
              }
            }
          }}
        >
          <iframe
            id="iframe"
            src={content.quizUrl}
            style={{
              border: 'none',
              height: '500px',
              width: '100%'
            }}
            title="Quiz"
          />
        </Dialog>
      )}
    </Box>
  );
}
